function isNull (str) {
  if (str === null || str === undefined || str == '') {
    return true
  }
  else {
    const regu = "^[]+$"
    const re = new RegExp(regu)
    return re.test(str)
  }
}

const pattern = /^([a-zA-Z\d][\w-]{2,})@(\w{2,})\.([a-z]{2,})(\.[a-z]{2,})?$/

function emailIsValidate (email) {
  let result = pattern.test(email)
  console.log("email validate result is")
  console.log(result)
  return result


}



export { isNull, emailIsValidate }