import axios from "axios"
import axiosRetry from "axios-retry"
import { history } from "./history"
console.log(`当前环境${process.env.NODE_ENV}`)
const hostOrigin = process.env.NODE_ENV === "development" ? 'https://localhost:7008' : window.location.origin
const http = axios.create({
  withCredentials: true,
  baseURL: hostOrigin + '/api',
  timeout: 5000 //应对Azure上由于连接提前关闭而拿不到响应，改为15000不管用，遂改回来
})
axiosRetry(http, {
  retries: 3, // 设置重试次数
  retryDelay: () => 500, // 设置重试延迟时间
  shouldResetTimeout: true, // 重置请求超时时间
  retryCondition: (error) => ['ECONNABORTED', 'ERR_NETWORK'].includes(error.code), // 重试条件
})
// 添加请求拦截器,统一注入token之类的
http.interceptors.request.use((config) => {
  return config
}, (error) => {
  return Promise.reject(error)
})

// 添加响应拦截器
http.interceptors.response.use((response) => {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  return response
}, (error) => {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  console.log(error)

  switch (error.response.status) //注意，不在组件之内的跳转，需要额外做些工作，比如用history 等
  {
    case 403://unauthorize，跳转到401页面
    case 401:
      history.push('/401')
      break

    case 404://file not found ，跳转到404页面
      history.push('/404')
      break
    case 500://跳转到500 error
      history.push('/500')
      break
    default: //跳转到500 error
      history.push('/500')
      break
  }

  return Promise.reject(error)
})

export { http }