import { Button, Divider, Radio, Modal } from "antd"
import useStore from "../../store"
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from "react"
import './index.css'
import { emailIsValidate, isNull } from "../../utils/stringValidationRules"
import { observer } from "mobx-react-lite"

const fontSizeStyle = {
  fontSize: 'small'
}

function Settings () {
  const { userStore } = useStore()

  console.log(userStore)
  //input 值的双向绑定
  const [emailInput, SetEmailInput] = useState(userStore.userProfile.basicInfo.email)
  const [nickNameInput, SetNickNameInput] = useState(userStore.userProfile.basicInfo.nickName)
  const [radioInput, SetRadioInput] = useState(userStore.userProfile.userPref.reminderFreq)
  const onEmailChange = (e) => {
    SetEmailInput(e.target.value)
  }
  const onNickNameChange = (e) => {
    SetNickNameInput(e.target.value)
  }
  const onRadioChange = (e) => {
    console.log(`radio checked:${e.target.value}`)
    SetRadioInput(e.target.value)
  }


  //input是否validate成功的状态
  const [nickNameValidate, SetNickNameValidate] = useState(true)
  const [emailValidate, SetEmailValidate] = useState(true)
  const navigate = useNavigate()
  const logOut = () => {
    userStore.clearUserProfileByLogout()
    navigate('/home')
  }


  const validateNickName = () => {
    if (isNull(nickNameInput)) {
      SetNickNameValidate(false)
    }
    else {
      SetNickNameValidate(true)
    }
  }

  const validateEmail = () => {
    if (!isNull(emailInput) && emailIsValidate(emailInput)) {
      SetEmailValidate(true)
    }
    else {
      SetEmailValidate(false)
    }

  }
  const validateAll = () => {
    validateNickName()
    validateEmail()
  }
  const handleSubmit = async () => {
    //再调用一次validate
    validateAll()
    if (nickNameValidate && emailValidate) {
      //真正提交
      console.log("验证都通过，开始提交")
      let response = await userStore.editUserProfile(nickNameInput, emailInput, radioInput)

      if (response.data.code == 1) {
        Modal.success({
          content: '保存成功',
        })
        userStore.getUserProfile()
      }
      else {
        Modal.error({
          title: '错误',
          content: '更改个人设置失败',
        })
      }

    }
    else {
      Modal.error({
        title: '错误',
        content: '输入有误，请重新输入',
      })
    }
  }
  //暂时不要重置这个功能了
  /* const handleReset = () => {
    SetEmailInput(userStore.userProfile.basicInfo.email)
    SetNickNameInput(userStore.userProfile.basicInfo.nickName)
    SetRadioInput(userStore.userProfile.userPref.reminderFreq)
    validateAll()


  } */
  return (
    <div id='SettingCanvas'>


      <div className='upperpanel'>
        <div className='headerinpanel'><span>基础信息</span></div>

        <div className='rowinpanel'><span>注册时间：</span><span>{userStore.userProfile.basicInfo.createdTime}</span></div>
        <div className='buttoninpanel'>
          <Button style={fontSizeStyle} type="primary" onClick={logOut}>退出登录</Button>
          {/* <Button style={fontSizeStyle} type="primary" onClick={() => navigate('/home')}>返回主页</Button> */}
        </div>
      </div>
      <Divider />
      <div className='lowerpanel'>
        <div className='headerinpanel'><span>其他信息</span></div>
        <div className='rowinpanel'><span>昵称：</span><input onChange={onNickNameChange} onBlur={validateNickName} value={nickNameInput}></input>{nickNameValidate === false ? <span className="validatemsg">昵称不能为空白</span> : null}</div>
        <div className='rowinpanel'><span>邮箱：</span><input disabled onChange={onEmailChange} onBlur={validateEmail} value={emailInput}></input>{emailValidate === false ? <span className="validatemsg">请检查邮件格式</span> : null}</div>
        <div className='specialrowinpanel'>

          <div><span>将用邮件提醒您“我的行动”-邮件频率：</span></div>

          <Radio.Group onChange={onRadioChange} defaultValue={radioInput} value={radioInput} buttonStyle="solid">
            <Radio.Button style={fontSizeStyle} value="w">周</Radio.Button>
            <Radio.Button style={fontSizeStyle} value="m">月</Radio.Button>
            <Radio.Button style={fontSizeStyle} value="s">季度</Radio.Button>
          </Radio.Group>
        </div>
        <div className='buttoninpanel'>
          <Button onClick={handleSubmit} style={fontSizeStyle} type="primary">保存</Button>
          <Button style={fontSizeStyle} type="primary" onClick={() => navigate('/home')}>返回主页</Button>
          {/* <Button onClick={handleReset} style={fontSizeStyle} type="primary">重置</Button> */}

        </div>
      </div>


    </div>

  )
}
export default observer(Settings)