import React from 'react'
import { Menu, theme } from 'antd'
import { HomeOutlined, SettingOutlined, HolderOutlined } from '@ant-design/icons'
import { useEffect } from 'react'
import useStore from '../store'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { history } from '../utils/history'
function getItem (label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  }
}

const pcMenuStyle = {
  border: 'none'
}
function PCMenu () {

  const { thumbnailStore, userStore } = useStore()
  //首次加载时获取数据
  useEffect(() => {
    //console.log('副作用执行了')
    if (thumbnailStore.arrThumbnail.length === 0) { thumbnailStore.getThumbnail() }
    //console.log(thumbnailStore)
  }, [])  //首次加载时先判断，再填充数据

  let items = []
  if (thumbnailStore.arrThumbnail.length > 0) {
    //Menu item
    const arrTitle = thumbnailStore.arrThumbnail.map((x) => ({ key: x.chapterNo, title: x.title }))
    items = arrTitle.map(item => getItem(item.title, item.key, <HolderOutlined />, null))
    items.push(getItem('个人中心', 999, <HomeOutlined />, null))
    items.unshift(getItem('主页', 0, <SettingOutlined />, null))
  }
  const navigate = useNavigate()

  const onClick = (e) => {
    switch (e.key) {
      case '999':
        if (userStore.isLogin) {
          navigate('/settings')
        }
        else {
          navigate('/login', { state: { url: window.location.href } }) //不是很友好，最好login之后，还能回到原来位置
        }
        break
      case '0':
        navigate('/home')
        break
      default:
        /* history.push('/help') // 先跳到空白页面，10毫秒后跳转到该去的地方，为了走一下路由鉴权
        setTimeout(() => {
          history.replace('/chapter?no=' + e.key)
        }, 10) */
        navigate('/chapter?no=' + e.key)
    }
  }
  return (
    <Menu style={pcMenuStyle}
      onClick={onClick}
      theme="light"
      mode="vertical"
      items={items}
    />
  )
}

export default observer(PCMenu)