import useStore from "../store"
import { Button, Collapse, Divider, Switch } from "antd"
import { EditOutlined, PlusOutlined } from "@ant-design/icons"
import { Link } from 'react-router-dom'
import { useState, useEffect, useContext } from "react"
import Context from "../utils/chapternoContext"
import MyModal from "./myModal"
import { http } from "../utils"
import { observer } from "mobx-react-lite"


const listStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  width: '100%',

}
const listStyleSpecial = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  width: '100%',
  cursor: 'cell'

}
const contentStyle = {
  width: '100%'

}

const iconStyle = {
  marginRight: '10px',

}
const textStyle = {
  marginBottom: '5px'
}


const timeStyle = {
  marginTop: '0px',
  fontSize: 'x-small',
  color: 'gray'
}






function NoteList (props) {
  const chapterNum = useContext(Context)
  const { commentStore, userStore } = useStore()
  useEffect(() => {
    if (props.children === 'note') {
      console.log("填充note,chapternum=")
      console.log(chapterNum)
      commentStore.getNote(chapterNum)
    }
    else {
      console.log("填充action,chapternum=")
      console.log(chapterNum)
      commentStore.getAction(chapterNum)
    }
  }, [chapterNum])

  //新增-模态窗口
  //模态窗口的开关状态
  const [openStateAddModal, setOpenStateAddModal] = useState(false)
  //点击-显示
  const handleAddNoteOrAction = () => {
    setOpenStateAddModal(true)
  }
  //为了子函数中的按钮能够改变开关状态 子传父中父准备好的回调函数
  const changeOpenStateInSonAddModal = (currOpenState) => {

    setOpenStateAddModal(currOpenState)
  }


  //编辑-模态窗口
  const [selectedId, setSelectedId] = useState(-1)
  //模态窗口的开关状态
  const [openStateEditModal, setOpenStateEditModal] = useState(false)

  //点击-显示
  const handleEditNoteOrAction = (id) => {
    console.log("点击了编辑按钮，这是父元素")
    setSelectedId(id)
    console.log("现在selectedId是")
    console.log(selectedId)

    setOpenStateEditModal(true)
  }
  //为了子函数中的按钮能够改变开关状态 子传父中父准备好的回调函数
  const changeOpenStateInSonEditModal = (currOpenState) => {

    setOpenStateEditModal(currOpenState)
  }



  //列表渲染，单另放入函数中
  const innerList = () => {
    if (props.children === 'note') {
      return commentStore.arrNote.map(item => (
        <div style={listStyle} key={item.id}>
          <div style={iconStyle} onClick={() => handleEditNoteOrAction(item.id)}><EditOutlined /></div>
          <div style={contentStyle}>
            <p style={textStyle}>{item.desc}</p>
            <div style={timeStyle}><Divider orientation="right" plain orientationMargin="0">{item.lastEditTime}</Divider></div>
          </div>
        </div>))
    }
    else {
      return commentStore.arrAction.map(item => (
        <div style={listStyle} key={item.id}>
          <div style={iconStyle} onClick={() => handleEditNoteOrAction(item.id)}><EditOutlined /></div>
          <div style={contentStyle}>
            <div>
              <Switch checked={item.remind} disabled={true} />
              <span style={{ marginLeft: '5px' }}>提醒我</span>
            </div>
            <p style={textStyle}>{item.desc}</p>
            <div style={timeStyle}><Divider orientation="right" plain orientationMargin="0">{item.lastEditTime}</Divider></div>
          </div>
        </div>))

    }
  }
  //准备好传入模态窗口的一些参数（要放入children中）
  let theUserId = userStore.userProfile.basicInfo.userId
  //let theChapterNo = new URL(window.location.href).searchParams.get('no')
  const getCurrItem = (type, id) => {
    if (type === 'note') {
      return commentStore.arrNote.find(a => a.id === id)
    }
    else {
      return commentStore.arrAction.find(a => a.id === id)
    }

  }

  return (
    <>
      <div onClick={handleAddNoteOrAction} style={listStyleSpecial}>
        <div style={iconStyle}><PlusOutlined /></div>
        <div style={contentStyle}>
          <p style={textStyle}>{props.children === 'note' ? '新增笔记' : '新增行动'}</p>
          <div style={timeStyle}><Divider> </Divider></div>
        </div>
      </div>
      {openStateAddModal ? <MyModal changeOpenState={changeOpenStateInSonAddModal} mode='add' type={props.children}>{{ userId: theUserId, chapterNo: chapterNum }}</MyModal> : null}
      {openStateEditModal ? <MyModal changeOpenState={changeOpenStateInSonEditModal} mode='edit' type={props.children}>{{ userId: theUserId, chapterNo: chapterNum, selectedItem: getCurrItem(props.children, selectedId) }}</MyModal> : null}

      {
        innerList()
      }
    </>
  )
}


export default observer(NoteList)
