import { makeAutoObservable } from "mobx"
import { http } from "../utils"


class thumbnailStore {
  arrThumbnail = []

  constructor() {
    makeAutoObservable(this)
  }

  getThumbnail = async () => {
    const res = await http.get('/getThumbnail')
    this.arrThumbnail = res.data.data
  }

  get menuThumbnail () {
    return [
      { key: 0, title: "主页" },
      ...this.arrThumbnail.map(a => ({ key: a.chapterNo, title: a.title })),
      { key: 999, title: "个人中心" }
    ]
  }

}

const theThumbnailStore = new thumbnailStore()
export default theThumbnailStore