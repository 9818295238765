import './index.css'
import { Layout, theme, Row, Col } from 'antd'
import React from 'react'
import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import PCMenu from '../../components/PCMenu'
import MobileMenuLine from '../../components/mobileMenuLine'
import CommentRegion from '../../components/commentRegion'
import ArticleContent from '../../components/articleContent'
import copy from 'copy-to-clipboard'
import Context from '../../utils/chapternoContext'
import AuthChapter from '../../components/authChapter'


const appendCopyWriteText = "--引用请注明出处 作者：仲华.书名：人生的蓝图 （尚未出版）--"
const { Header, Content, Footer, Sider } = Layout

function getItem (label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  }
}
function Chapter () {

  const {
    token: { colorBgContainer },
  } = theme.useToken()
  //本来写在css文件中，但是backgroundColor无法生效，发现ant自身已有background color,于是改成行内样式级别高一些，所以生效了。
  const layoutStyle = {

    backgroundColor: 'white',
  }
  //这是钩子函数获取路由的参数，会自动监听url并且实时变化
  let [params] = useSearchParams()
  //当前章节作为一个数据
  const [currChapterNo, setCurrChapterNo] = useState(params.get('no')) //赋初值
  // watch 路由中参数变化-->state变化 -->重新渲染组件
  useEffect(() => {
    console.log('路由参数变化了')
    window.scrollTo(0, 0) //每次切换页面都滚动到页头
    setCurrChapterNo(params.get('no'))
  }, [params])

  const AppendCopyRightOnCopy = (e) => {
    e.preventDefault()
    console.log("有人复制了")
    //let copyText = e.clipboardData.getData("text")
    //let copyText2 = navigator.clipboard.readText()
    let copyText = window.getSelection().toString()
    copyText = appendCopyWriteText + copyText + appendCopyWriteText
    copy(copyText)
  }



  return (
    <Context.Provider value={currChapterNo}>
      <Layout style={layoutStyle}>
        <Content id="ChapterContent"
          style={{
            margin: '24px 16px 0',
          }}
        >
          <div>


            <Row>
              <Col xs={0} sm={0} md={0} lg={6} xl={6} xxl={6}>
                <PCMenu></PCMenu>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <MobileMenuLine></MobileMenuLine>
                <div id="FormalBody" onCopy={AppendCopyRightOnCopy}><AuthChapter><ArticleContent></ArticleContent></AuthChapter>

                </div>
                <div id="ReturnTop"><a href="#ChapterContent">回到页首</a></div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}><div id="commentstart"><CommentRegion></CommentRegion></div>
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
    </Context.Provider>

  )
}
export default Chapter