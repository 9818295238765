import './index.css'
import { Carousel, Col, Row } from 'antd'
import useStore from '../../store'
import { Link } from 'react-router-dom'
import { useObserver, observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useEffect } from 'react'





const HeaderLink = observer(() => {

  const { userStore } = useStore()
  const logout = () => {

    userStore.clearUserProfileByLogout()

  }
  return (userStore.userProfile === null ? (
    <div><Link to='/login' state={{ url: window.location.href }}>登录/注册</Link></div>
  ) : (
    <>
      <div><Link to='/settings'>{userStore.userProfile.basicInfo.nickName + '的个人中心'}</Link></div>
      <div><span id="LogOut" onClick={logout}>退出</span>
      </div>
    </>
  ))


})

function Home () {
  const { thumbnailStore } = useStore()
  //console.log(thumbnailStore.arrThumbnail)
  useEffect(() => {
    //console.log('副作用执行了')
    thumbnailStore.getThumbnail()
    //console.log(thumbnailStore)
  }, [])  //首次加载时填充数据  

  const onClickChapter = () => {


  }

  return (
    <div className="screenwrapper">
      <section className="header">
        <HeaderLink></HeaderLink>
      </section>
      <section className="bannerswiper">

        <Carousel autoplay>

          <div className="item active">
            <div>
              <img src={require("../../assets/img/Marriage3.jpg")} alt="家庭和睦" />
              <div className="carousel-caption">
                美满的人生
              </div>
            </div>
          </div>
          <div className="item">
            <div>
              <img src={require("../../assets/img/PurposeOfLife1.jpg")} alt="人生有意义" />
              <div className="carousel-caption">
                有意义的人生
              </div>
            </div>
          </div>
          <div className="item">
            <div>
              <img src={require("../../assets/img/PurposeOfLife4.jpg")} alt="人生有方向" />
              <div className="carousel-caption">
                有方向的人生
              </div>
            </div>
          </div>

        </Carousel>



      </section>
      <section className="booktitle">
        <p>人生的蓝图</p>
      </section>
      <section className="content container">
        <Row>
          {
            thumbnailStore.arrThumbnail.map((item) =>
            (
              <Col key={item.chapterNo} className="chapteritem" xs={12} sm={12} md={6} lg={6}>
                <Link to={'/chapter?no=' + item.chapterNo}>
                  <img alt="序言的图片" src={require('../../assets/img/' + item.chapterImg)} />
                  <p className="chaptertitle">{item.title}</p>
                  <p className="chaptersubtitle1">{item.subTitleLine1}</p>
                  <p className="chaptersubtitle2">{item.subTitleLine2}</p>
                </Link>
              </Col>)

            )
          }




        </Row>

      </section>
      <section className="bottom">
        <Link to="/Copyright"> 人生的蓝图 ©2024 版权声明</Link>
      </section>
    </div>

  )
}
export default observer(Home)