import React from "react"
import theUserStore from "./user.Store"
import theThumbnailStore from "./thumbnail.Store"
import theCommentStore from "./Comment.Store"

class RootStore {
  constructor() {
    this.userStore = theUserStore
    this.thumbnailStore = theThumbnailStore
    this.commentStore = theCommentStore
  }



}

const rootStore = new RootStore()

const context = React.createContext(rootStore)

const useStore = () => React.useContext(context)

export default useStore