import React from 'react'
import { Button, Result } from 'antd'
import { SmileOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
function Copyright () {
  const navigate = useNavigate()

  return (
    <Result

      title="本网站为公益性质（永久），文章版权归作者所有，转载请注明出处。合作事宜请联系小助理"
      subTitle="图片会逐步替换为自有版权的图片，当前图片若侵权，请联系小助理，小助理将与之删除。小助理邮箱：blueprintassistant@outlook.com"

      extra={<Button onClick={() => navigate('/home')} type="primary">回首页</Button>}
    />
  )
}

export default Copyright