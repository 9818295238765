//import jsonbook from '../assets/book/demo.json'
import { useNavigate } from "react-router-dom"
import FileNotFound404 from "../pages/errorpages/error404"
import { useContext } from "react"
import Context from "../utils/chapternoContext"
import { isNull } from "../utils/stringValidationRules"
const liStyle = {
  padding: '10px 2px',
  fontFamily: "fangsong"
}
const refStyle = {
  // borderTop: "1px dotted gray",
  padding: "12px 0px",
  fontFamily: "fangsong"
}

const bingSearchUrl = "https://cn.bing.com/search?q="
function renderSection (item) {

  //console.log(item)
  switch (item.category) {
    case "title":
      return <h1>{item.text}</h1>
    case "paragraph":
      return <p>{item.text}</p>
    case "story":
      return <p className="Story">{item.text}</p>
    case "img":
      return <div className="ImgWrapper"><img src={require("../assets/img/" + item.url)}></img></div>
    case "link":
      //item.links.sort((a, b) => a.subOrder < b.subOrder) //这句竟然不管用
      //console.log(item.links)

      return (<><div style={refStyle}>参考资料:</div><ol>{item.links.map((a) => <li style={liStyle} key={a.subOrder}>{isNull(a.fixLink) && isNull(a.keyWord) ? <span>{a.text}</span> : <a href={isNull(a.fixLink) ? (bingSearchUrl + a.keyWord) : a.fixLink} target="_blank">{a.text}</a>}</li>)}</ol></>)
    default:
      return <div>不知道</div>
  }
}
function ArticleContent () {
  const navigate = useNavigate()
  //接收章节序号 读取相应序号.json文件
  let chapterno = useContext(Context)
  try {
    const book = require('../assets/book/' + chapterno + '.json')
    //console.log(book)
    const arrSections = book.sort((a, b) => a.order - b.order)
    //console.log(arrSections)
    return arrSections.map(item => <div key={item.order}>{renderSection(item)}</div>)
  }
  catch (e) { //找不到章节
    console.log(e)
    return <FileNotFound404></FileNotFound404>
  }
}

export default ArticleContent


