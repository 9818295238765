import useStore from "../store"
import { Button, Collapse, Divider, Switch } from "antd"
import { EditOutlined, PlusOutlined } from "@ant-design/icons"
import { Link } from 'react-router-dom'
import { useState } from "react"
import MyModal from "./myModal"
import { http } from "../utils"
import NoteList from "./noteList"




function CollapseComp () {


  const text = '我是填充的'
  const items = [
    {
      key: '1',
      label: '我的笔记',
      children: <NoteList>note</NoteList>,
    },
    {
      key: '2',
      label: '我的行动',
      children: <NoteList>action</NoteList>,
    }
  ]
  const onChange = (key) => {
    console.log(key)
  }
  return (<Collapse items={items} defaultActiveKey={['1']} onChange={onChange} />)


}
function CommentRegion () {
  const { userStore } = useStore()

  return userStore.isLogin ? (

    <CollapseComp></CollapseComp>


  ) : <Link to='/login' state={{ url: window.location.href }}>写笔记或行动，请先登录</Link>
}

export default CommentRegion
