import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, Modal, message } from 'antd'
import './index.css'
import useStore from '../../store'
import { useNavigate, useLocation, Link } from 'react-router-dom'


function whereToJumpAccordingState (state) {
  console.log('location.state的值')
  console.log(state)
  const whereToJumpAccordingUrl = (url_string) => {
    const url = new URL(url_string)
    console.log(url)
    const path = url.pathname
    const queryNo = url.searchParams.get('no')
    switch (path) {
      case '/home':
        return '/home'
      case '/settings':
        return '/'
      case '/chapter':
        return '/chapter?no=' + (queryNo ?? '1')
      default:
        return '/home'
    }
  }
  if (state !== null) {
    return whereToJumpAccordingUrl(state.url)
  }
  else {
    return '/'
  }
}

function Login () {


  const [form] = Form.useForm()//antD中form组件自己封装好的钩子函数
  const { userStore } = useStore()//store
  const location = useLocation()
  const navigate = useNavigate()//路由

  //点击提交
  const onFinish = async (values) => {

    await userStore.fillUserProfileByLogin(values.username, values.password)
    //注意，这里可以异步啊，会不会直接就向下走了？还是说，必须要求上面那个完全完毕，才能走啊，也就是说，上面一步加上await,下面就必须是完成后才走
    //失败后弹出错误，点击OK后清空登录表,这个失败这个地方处理的不好，其实走不都这里

    if (!userStore.isLogin) {
      Modal.error({
        title: '错误',
        content: '登录失败，请重新输入用户名和密码',
      })
      //清空用户名和密码
      form.resetFields()
    }
    else {
      message.success({
        type: '成功',
        content: '登录成功',
      })
      //跳转到原来页
      console.log('location的值')
      console.log(location)
      navigate(whereToJumpAccordingState(location.state))

    }
  }


  return (
    <div className="container">
      <p className="login-label">用户登录</p>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input your Username!',
            },
          ]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="用户名" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your Password!',
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="密码"
          />
        </Form.Item>
        <Form.Item>
          <Link className="login-form-forgot" to="/forgetpwd">
            忘记密码
          </Link>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button">
            登录
          </Button>
          <p className="register-label"><Link to="/register">注册新用户</Link></p>
        </Form.Item>
      </Form>
    </div>
  )
}
export default Login