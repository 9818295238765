import { makeAutoObservable } from "mobx"
import { http } from "../utils"

import qs from "qs"


class CommentStore {
  arrNote = [
    {
      "id": 1,
      "desc": "storelocal我觉得我这点就是很庆幸提前知道了，要不然现在就惨了。",
      "lastEditTime": "2021-1-28 3:20"
    },
    {
      "id": 2,
      "desc": "storelocal哎，谁教我当初不知道这件事呢，现在可好。",
      "lastEditTime": "2021-1-31 5:21"
    }
  ]
  arrAction = [
    {
      "id": 1,
      "desc": "storelocal我真的需要每月整理一下支出，并且做计划了。",
      "lastEditTime": "2021-1-28 3:20",
      "remind": true
    },
    {
      "id": 2,
      "desc": "storelocal我得培养一些不花钱的爱好了。",
      "lastEditTime": "2021-3-29 3:20",
      "remind": false
    }
  ]

  constructor() {
    makeAutoObservable(this)
  }

  getNote = async (chapterNo) => {



    const res = await http.get('/getNote?chapterNo=' + chapterNo)
    this.arrNote = res.data.data
  }

  getAction = async (chapterNo) => {

    const res = await http.get('/getAction?chapterNo=' + chapterNo)
    this.arrAction = res.data.data

  }

  addNote = async (chapterno, note) => {

    const res = await http.post('/addNote', qs.stringify({ 'chapterNo': chapterno, 'note': note })

    )

    return res
  }

  addAction = async (chapterno, action, remind) => {

    const res = await http.post('/addAction', qs.stringify({ 'chapterNo': chapterno, 'action': action, 'remind': remind })

    )

    return res
  }

  delNote = async (noteid) => {
    const res = await http.post('/delNote', qs.stringify({ 'noteId': noteid }))

    return res
  }

  delAction = async (actionid) => {
    const res = await http.post('/delAction', qs.stringify({ 'actionId': actionid }))
    console.log(res)
    return res
  }

  editNote = async (noteid, desc) => {

    const res = await http.post('/editNote', qs.stringify({ 'noteId': noteid, "desc": desc }))


    return res

  }



  editAction = async (actionid, desc, remind) => {
    const res = await http.post('/editAction', qs.stringify({ 'actionId': actionid, "desc": desc, "remind": remind }))


    return res

  }





}

const theCommentStore = new CommentStore()
export default theCommentStore