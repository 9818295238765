import React from 'react'
import { Button, Result } from 'antd'
import { useNavigate } from 'react-router-dom'
function ServerInternalError500 () {
  const navigate = useNavigate()


  return (
    <Result
      status="500"
      title="500"
      subTitle="出了点故障."
      extra={<Button onClick={() => { navigate('/home') }} type="primary">返回主页</Button>}
    />)
}

export default ServerInternalError500