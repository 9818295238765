import React from 'react'
import { Button, Result } from 'antd'
import { useNavigate } from 'react-router-dom'
function NoAuthorityVisit () {
  const navigate = useNavigate()

  //不想直接说，没有给你权限，所以骗你说，尚未完成敬请期待
  return (
    <Result
      status="500"
      title="hmm……"
      subTitle="尚未完成，敬请期待……"
      extra={<Button onClick={() => { navigate('/home') }} type="primary">返回主页</Button>}
    />)
}

export default NoAuthorityVisit