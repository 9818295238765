import MyMenu from './PCMenu.js'
import MyWrittenMenuForMobile from './myWrittenMenuForMobile.js'
import { MenuUnfoldOutlined } from '@ant-design/icons'
import { useState, useEffect } from 'react'

const styleInMoble = {
  display: 'flex',
  flexDirection: 'row',
  /* border: '1px solid red' */
}

const styleInPC = {
  display: 'none',
  // border: '1px solid red'
}

const buttonStyle = {
  display: 'inline-block',
  width: '30px'
}

const anchorStyle = {
  padding: '5px 15px',
  lineHeight: '20px',
  color: '#555',
  backgroundColor: '#e7e7e7',
  display: 'inline-block'

}



// 移动端显示的menu,平时显示button,点击后出现menu,menu是我自己写的myWrittenMenu组件
//另外，加上定位到评论区的锚点链接

function MobileMenuLine () {
  //响应屏幕宽度变化
  //建立一个状态来表示当前的屏幕宽度,初值为当时的innerwidth
  const breakpoint = 992 //bootstrap中sm 和ml的分界点
  const [width, setWidth] = useState(window.innerWidth)
  console.log("width")
  console.log(width)
  //在组件最初渲染时，添加监听resize事件，及时改变state中的width值.组件卸载时，清理副作用
  useEffect(() => {
    const handleWindowWidth = () => { setWidth(window.innerWidth) }
    window.addEventListener("resize", handleWindowWidth)
    return () => window.removeEventListener("resize", handleWindowWidth)
  }, [])
  //组件会随着width值变化而重新渲染

  //点击按钮实现菜单展开和隐藏
  const [showMenu, setShowMenu] = useState(false)
  const toggleShowMenu = () => {
    setShowMenu(!showMenu)
  }
  const closeMenuInSon = (toCloseMenu) => {
    setShowMenu(!toCloseMenu)
  }
  return (
    <div style={width < breakpoint ? styleInMoble : styleInPC}>
      <button onClick={toggleShowMenu} style={buttonStyle}> <MenuUnfoldOutlined /> </button>
      <div >
        <a style={anchorStyle} href="#commentstart">笔记与行动</a>
      </div>
      {showMenu ? <MyWrittenMenuForMobile closeMenuFunc={closeMenuInSon}></MyWrittenMenuForMobile> : <div></div>}
    </div>
  )
}

export default MobileMenuLine