const USER_KEY = 'user'
const CURR_USER_MODEL = {
  basicInfo: { userId: 2, nickName: "She", email: "lishe@126.com", createdTime: "2023/8/1" },
  userAuth: { allowReadAll: false, allowedChapters: [1, 2, 3] },
  userPref: { reminderFreq: "m" }
}

//每次网站维护后，如果User模型有了新字段（或调整了结构）而用户localstorage里存的模型仍然是旧的，还原为model后面使用就会报错。
//网站更新后又无法告知每个用户提前清楚缓存
//所以从localstorage取出来后，先与最新的Use模型比较一下是否完整（CURR_USER_MODEL就是最新的模型，每次更新后都会维护这里。）完整的话再使用

const getLocalProfile = () => {
  //localStorage.getItem(USER_KEY)
  let storedjson = localStorage.getItem(USER_KEY)
  if (storedjson == null) {
    return null
  }
  else {
    let storedObj = JSON.parse(storedjson)
    if (keysInB(CURR_USER_MODEL, storedObj)) {//localstorage
      //console.log("B是完整的")
      return storedjson
    }
    else { //清掉它，省得以后报错
      //console.log("B不完整")
      clearLocalProfile()
      return null
    }

  }

}

function keysInB (a, b) {
  // console.log("调用了一次KeysInB函数")
  var aKeys = Object.keys(a)
  //console.log(`akey的值是 ${aKeys}`)
  var bKeys = Object.keys(b)
  //console.log(`bKeys的值是 ${bKeys}`)
  // 检查 a 对象的所有键是否都在 b 对象中
  return aKeys.every(function (key) {
    //console.log(`正在比较该key ${key}`)

    if (typeof a[key] === 'object' && !Array.isArray(a[key])) {
      // 如果 a 对象的键是一个对象，递归调用 keysInB
      if (b[key] == null || b[key] == undefined) {
        //console.log(`B缺少了这个key ${key},直接就返回false`)
        return false
      }
      else {
        return keysInB(a[key], b[key])
      }
    } else {
      return bKeys.includes(key)
    }
  })
}



const setLocalProfile = userjson => localStorage.setItem(USER_KEY, userjson)
const clearLocalProfile = () => localStorage.removeItem(USER_KEY)

export { getLocalProfile, setLocalProfile, clearLocalProfile }