import { unstable_HistoryRouter as HistoryRouter, BrowserRouter, Routes, Route, Link } from 'react-router-dom'
import './App.css'
import { history } from './utils/history'
import Settings from './pages/settings'
import Login from './pages/login'
import Register from './pages/register'
import Chapter from './pages/chapter'
import AuthChapter from './components/authChapter'
import Help from './pages/help'
import Home from './pages/home'
import FileNotFound404 from './pages/errorpages/error404'
import ServerInternalError500 from './pages/errorpages/error500'
import Unauthorize401 from './pages/errorpages/error401.js'
import NoAuthorityVisit from './pages/errorpages/noAuthorityVisit'
import Copyright from './pages/otherpages/copyright.js'
import Forgetpwd from './pages/otherpages/forgetpwd.js'
function App () {
  //路由配置

  return (
    <div className="App">
      <HistoryRouter history={history}>

        <Routes>
          <Route path="/home" element={<Home></Home>}></Route>
          <Route path="/index.html" element={<Home></Home>}></Route>
          <Route path="/" element={<Home></Home>}></Route>
          <Route path="/login" element={<Login></Login>}></Route>
          <Route path="/register" element={<Register></Register>}></Route>
          <Route path="/settings" element={<Settings></Settings>}></Route>
          <Route path="/chapter" element={<Chapter />}></Route>
          <Route path="/help" element={<Help></Help>}></Route>
          <Route path="/copyright" element={<Copyright></Copyright>}></Route>
          <Route path="/forgetpwd" element={<Forgetpwd></Forgetpwd>}></Route>
          <Route path="/401" element={<Unauthorize401></Unauthorize401>}></Route>
          <Route path="/404" element={<FileNotFound404></FileNotFound404>}></Route>
          <Route path="/500" element={<ServerInternalError500></ServerInternalError500>}></Route>
          <Route path="/underconstruction" element={<NoAuthorityVisit></NoAuthorityVisit>}></Route>
          <Route path='*' element={<FileNotFound404></FileNotFound404>}></Route>
        </Routes>
      </HistoryRouter>
    </div>
  )

}

export default App
