import React from 'react'
import { Button, Result } from 'antd'
import { useNavigate } from 'react-router-dom'
function Unauthorize401 () {
  const navigate = useNavigate()

  return (
    <Result
      status="403"
      title="401"
      subTitle="您需要先登录."
      extra={<Button onClick={() => navigate('/login')} type="primary">去登录</Button>}
    />
  )
}

export default Unauthorize401