import './myModal.css'
import { useState } from 'react'
import useStore from '../store'
import { Button, Switch, Tooltip, Popconfirm } from 'antd'
import { isNull } from "../utils/stringValidationRules"
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { http } from '../utils'
//新增笔记或者新增行动的模态窗口
function MyModal (props) {

  const { commentStore } = useStore()


  //模态窗口的关闭，调用了父组件传过来的函数，为了把改变父组件的状态。（模态窗口的状态在父组件中）

  const closeModal = () => {
    props.changeOpenState(false)
  }
  //我的行动中的开关组件
  const [switchState, setSwitchState] = useState((props.mode === 'edit') && (props.type === 'action') ? props.children.selectedItem.remind : true)
  const onSwitchChange = (checked) => {
    console.log(`switch to ${checked}`)
    setSwitchState(checked)
  }

  //textarea的文字
  const [text, setText] = useState(props.mode === 'edit' ? props.children.selectedItem.desc : '')
  const textChange = (e) => {
    setText(e.target.value)
  }

  //副作用
  useEffect(() => {
    //console.log('myModal里的副作用执行了')
    setText(props.mode === 'edit' ? props.children.selectedItem.desc : '')
    setSwitchState((props.mode === 'edit') && (props.type === 'action') ? props.children.selectedItem.remind : true)
  }, [props.children.selectedItem, props.type])

  //按下确认键

  const handleModalOK = async () => {
    if (!isNull(text)) {
      let response = null
      if (props.type === 'note') {

        if (props.mode === 'add') {
          response = await commentStore.addNote(props.children.chapterNo, text)
        }
        else { //edit note
          response = await commentStore.editNote(props.children.selectedItem.id, text)
        }
        if (response.data.code == 1) //  失败会有弹窗的,且模态不会关闭
        {
          console.log("添加/编辑笔记成功")
          commentStore.getNote(props.children.chapterNo)//刷新Store中的数据
          closeModal()
        }
      }
      else  //props.type === 'action')
      {
        if (props.mode === 'add') {

          console.log('请求添加行动')
          response = await commentStore.addAction(props.children.chapterNo, text, switchState)
        }
        else //props.mode==='edit'
        {
          console.log('请求编辑行动')
          response = await commentStore.editAction(props.children.selectedItem.id, text, switchState)
        }

        console.log(response)
        if (response.data.code == 1) //  失败会有弹窗的,且模态不会关闭
        {
          console.log("添加/编辑行动成功")
          commentStore.getAction(props.children.chapterNo)//刷新Store中的数据
          closeModal()
        }
      }

    }
  }
  //按下删除键，弹出确认框
  const confirmDelete = async (e) => {
    let response = null
    console.log(e)
    if (props.type === 'note') {
      console.log('请求删除笔记')
      response = await commentStore.delNote(props.children.selectedItem.id)
      if (response.data.code == 1) {
        console.log("删除笔记成功")
        commentStore.getNote(props.children.chapterNo)//刷新Store中的数据
        closeModal()
      }
    }
    else {
      console.log('请求删除行动')
      response = await commentStore.delAction(props.children.selectedItem.id)
      if (response.data.code == 1) {
        console.log("删除行动成功")
        commentStore.getAction(props.children.chapterNo)//刷新Store中的数据
        closeModal()
      }
    }
  }
  const cancelDelete = (e) => {
    console.log('又不删除了，不用做什么')
  }

  return (
    <div id="myModal" className="modalopen" >

      {/* 模态内容  */}
      <div className="modal-content">
        <div className="modal-header">

          <h2>{(props.mode === 'add' ? '新增' : '编辑') + (props.type === 'note' ? '笔记' : '行动')}</h2>
        </div>
        <div className="modal-body">
          <textarea value={text} onChange={textChange} placeholder='写点什么'></textarea>
          {props.type === 'action' ?
            (<div>
              <Tooltip title="个人中心可设置提醒频率">
                <Switch checked={switchState} onChange={onSwitchChange} />
              </Tooltip>
              <span>按时提醒我</span>
            </div>) : <></>
          }
        </div>
        <div className="modal-footer">
          <div>
            <Button onClick={handleModalOK}>确认</Button><Button onClick={closeModal}>取消</Button>
          </div>
          {props.mode === 'edit' ? <div><Popconfirm title="删除确认" description="确认删除该条目吗?" onConfirm={confirmDelete} onCancel={cancelDelete} okText="Yes" cancelText="No"><Button danger>删除</Button></Popconfirm></div> : <></>}
        </div>
      </div>

    </div>

  )

}
export default observer(MyModal)

