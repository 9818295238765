//1. 判断该用户是否登录，未登录，跳转到登录界面
//2. 判断该用户允许访问的chapter里面是否有当前url中所说的chapter,没有，跳转到UnderConstruction界面
import useStore from '../store'
import { Navigate } from 'react-router-dom'
import getCurrChapterNo from '../utils/getcurrentchapterno'
import NoAuthorityVisit from '../pages/errorpages/noAuthorityVisit'


function hasAuthorization (userProfile) {


  if (userProfile.userAuth.allowReadAll)//允许阅读全部
  {
    return true
  }
  else {//不允许阅读全部
    if (userProfile.userAuth.allowedChapters.some(a => a == getCurrChapterNo())) //当前章在允许的范围内
    {

      return true
    }
    else { //当前章不在允许的范围内
      return false
    }

  }

}
function AuthChapter ({ children }) {
  const publicDisplayChapter = ["1"]//注意取回路由中的参数是字符串格式
  const { userStore } = useStore()//store
  const currChapterNo = getCurrChapterNo()
  //console.log(currChapterNo)
  if (publicDisplayChapter.includes(currChapterNo)) {
    return <>{children}</>
  }
  else {

    if (userStore.isLogin) {

      if (hasAuthorization(userStore.userProfile)) {
        return <>{children}</>
      }
      else {
        return <NoAuthorityVisit></NoAuthorityVisit>
      }

    }
    else {
      return <Navigate to='/login' replace></Navigate>
    }
  }



}

export default AuthChapter
