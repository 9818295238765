
import './index.css'
import useStore from '../../store'
import { useNavigate, useLocation } from 'react-router-dom'
import React, { useState } from 'react'
import {
  Modal,
  message,
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Select,
} from 'antd'
const { Option } = Select
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
}



function Register () {
  const { userStore } = useStore()//store
  const navigate = useNavigate()//路由
  const [form] = Form.useForm()
  const onFinish = async (values) => {
    console.log('Received values of form: ', values)
    await userStore.fillUserProfileByRegister(values)
    if (!userStore.isLogin) {
      Modal.error({
        title: '错误',
        content: '登录失败，请用刚注册的邮箱和密码尝试登录',
      })
      navigate("/login")
    }
    else {
      message.success({
        type: '成功',
        content: '注册成功',
      })
      //跳转到首页  
      navigate("/home")
    }
  }



  return (
    <div className="container">
      <p className="top-label">新用户注册</p>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        //initialValues={{
        //yearofbirth: 1990,

        //}}
        style={{
          maxWidth: 600,
        }}
        scrollToFirstError
      >
        <Form.Item
          name="email"
          label="E-Mail"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="password"
          label="密码"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="确认密码"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: '请再次确认密码!',
            },
            ({ getFieldValue }) => ({
              validator (_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('The new password that you entered do not match!'))
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="nickname"
          label="昵称"

          rules={[
            {
              required: true,
              message: '请填写昵称!',
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="sex"
          label="性别"
          rules={[
            {
              required: true,
              message: '请选择性别!',
            },
          ]}
        >
          <Select placeholder="选择性别">
            <Option value="0">男</Option>
            <Option value="1">女</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="yearofbirth"
          label="出生年份"
          rules={[
            {
              required: true,
              message: '请填写出生年',
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="edulevel"
          label="教育背景"
          rules={[
            {
              required: true,
              message: '请告诉我们您的教育情况',
            },
          ]}>
          <Select>
            <Select.Option value="4">硕士/博士</Select.Option>
            <Select.Option value="3">本科</Select.Option>
            <Select.Option value="2">大专/高职</Select.Option>
            <Select.Option value="1">高中/中专/中职</Select.Option>
            <Select.Option value="0">初中及以下</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="howtoknowus"
          label="了解我们的渠道"
          rules={[
            {
              required: true,
              message: '请告诉我们您如何得知这个网站',
            },
          ]}
        >
          <Select>
            <Select.Option value="0">朋友直接告诉我</Select.Option>
            <Select.Option value="1">朋友圈/微信群</Select.Option>
            <Select.Option value="2">喜马拉雅</Select.Option>
            <Select.Option value="3">豆瓣</Select.Option>
            <Select.Option value="4">抖音或小红书</Select.Option>
          </Select>
        </Form.Item>
        <p>本网站为公益性质，用户数据不会提供给第三方或做任何商业用途</p>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            注册
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
export default Register