import React from 'react'
import { Button, Result } from 'antd'
import { SmileOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
function Forgetpwd () {
  const navigate = useNavigate()

  return (
    <Result
      icon={<SmileOutlined />}
      title="请给小助理写信 blueprintassistant@outlook.com,并附上你注册时的电子邮件地址"
      extra={<Button onClick={() => navigate('/home')} type="primary">回首页</Button>}
    />
  )
}

export default Forgetpwd