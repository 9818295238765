import React from 'react'
import './myWrittenMenuForMobile.css'

import { SettingOutlined, HomeOutlined, HolderOutlined } from '@ant-design/icons'
import { useEffect } from 'react'
import useStore from '../store'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { history } from '../utils/history'

// 这是用在mobile menu line 中


function MyWrittenMenuForMobile ({ closeMenuFunc }) {

  const { thumbnailStore, userStore } = useStore()

  //获取数据
  useEffect(() => {
    //console.log('副作用执行了')
    if (thumbnailStore.arrThumbnail.length === 0) { thumbnailStore.getThumbnail() }
    // console.log(thumbnailStore)
  }, [])  //首次加载时先判断，再填充数据

  const iconitem = (num) => {
    switch (num) {
      case 0:
        return <HomeOutlined />
      case 999:
        return <SettingOutlined />
      default:
        return <HolderOutlined />
    }
  }
  const navigate = useNavigate()
  const onClickMenu = (key) => {
    //console.log(key)
    switch (key) {
      case 999:
        if (userStore.isLogin) {
          navigate('/settings')
        }
        else {
          navigate('/login', { state: { url: window.location.href } })
        }
        break
      case 0:
        navigate('/home')
        break
      default:
        /* history.push('/help') // 先跳到空白页面，10毫秒后跳转到该去的地方，为了走一下路由鉴权
        setTimeout(() => {
          history.replace('/chapter?no=' + key.toString())
          closeMenuFunc(true)
        }, 10) */
        navigate('/chapter?no=' + key.toString())
        closeMenuFunc(true)
    }
  }



  return (

    <ul className='mobilecustomnav'>
      {thumbnailStore.menuThumbnail.map((a) => <li onClick={() => onClickMenu(a.key)} key={a.key}>{iconitem(a.key)}{a.title}</li>)}
    </ul>

  )
}

export default observer(MyWrittenMenuForMobile)