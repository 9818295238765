import { makeAutoObservable } from "mobx"
import { http } from "../utils"
import { getLocalProfile, setLocalProfile, clearLocalProfile } from '../utils/persistUserProfile'
import md5 from "js-md5"

class userStore {

  userProfile = JSON.parse(getLocalProfile() || null)  //Json.Parse(null)返回null

  constructor() {
    makeAutoObservable(this)

  }


  fillUserProfileByLogin = async (username, password) => {
    //发送请求
    const md5pwd = md5(password)
    const res = await http.post('/login', { username, password: md5pwd })
    if (res.data.code == 1)//登录成功
    //接受数据,填充前端对象
    {
      this.fillUserProfile(res)
    }
    else { //登录失败
      this.userProfile = null
    }

  }
  fillUserProfileByRegister = async ({ email, password, nickname, yearofbirth, sex, edulevel, howtoknowus }) => {
    //console.log(`In Ajax email= ${email},password=${password},nickname=${nickname},yearofbirth=${yearofbirth},sex=${sex},edulevel=${edulevel},howtoknowus=${howtoknowus}`)
    const md5pwd = md5(password)
    //console.log(`md5pwd=${md5pwd}`)
    const sendObj = {
      email: email,
      password: md5pwd,
      nickname: nickname,
      yearofbirth: yearofbirth,
      sex: sex,
      edulevel: edulevel,
      howtoknowus: howtoknowus
    }
    //console.log(sendObj)
    const res = await http.post('/registration', sendObj)
    if (res.data.code == 1)//注册用户并且登录成功
    {
      this.fillUserProfile(res)
    }
    else {
      this.userProfile = null
    }
  }
  //填充用户数据的方法，fillUserProfileByLogin 和 fillUserProfileByRegister调用
  fillUserProfile = (res) => {
    this.userProfile = {
      basicInfo: {
        userId: res.data.data.userId,
        nickName: res.data.data.nickName,
        email: res.data.data.email,
        createdTime: res.data.data.createdTime
      },
      userPref: {
        reminderFreq: res.data.data.reminderFrequency
      },
      userAuth: {
        allowReadAll: res.data.data.allowReadAll,
        allowedChapters: res.data.data.allowReadAll ? null : res.data.data.allowedChapters.split(',').filter(Boolean).map(Number)
      }
    }
    console.log(this.userProfile)

    //放入localstorage一份
    setLocalProfile(JSON.stringify(this.userProfile))
  }

  getUserProfile = async () => {
    console.log('调用了getUserProfile')
    //发送请求
    const res = await http.get('/getUserProfile')
    console.log('getUserProfile请求完成')

    //接受数据,填充前端对象
    this.userProfile = {
      basicInfo: {
        userId: res.data.data.userId,
        nickName: res.data.data.nickName,
        email: res.data.data.email,
        createdTime: res.data.data.createdTime
      },
      userPref: {
        reminderFreq: res.data.data.reminderFrequency
      },
      userAuth: {
        allowReadAll: res.data.data.allowReadAll,
        allowedChapters: res.data.data.allowReadAll ? null : res.data.data.allowedChapters.split(',').filter(Boolean).map(Number)
      }
    }
    //放入localstorage一份
    setLocalProfile(JSON.stringify(this.userProfile))

  }

  get isLogin () {
    if (this.userProfile === null)
      return false
    else
      return true
  }
  clearUserProfileByLogout = async () => {

    const res = await http.post('/logout')

    this.userProfile = null
    //localstorage的也清除
    clearLocalProfile()
  }

  editUserProfile = async (nickName, email, reminderFreq) => {
    const res = await http.post('/editUserProfile', {
      "nickName": nickName,
      "email": email,
      "reminderFrequency": reminderFreq
    })
    console.log(res)
    return res

  }

}
const theUserStore = new userStore()
export default theUserStore